import styled from '@emotion/styled'
import { AnimatedTitle } from 'app/components/Common/Animation/AnimatedTitle'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Line } from 'app/components/Common/Line'
import React, { memo } from 'react'

export interface Props {
  description?: string
  pretitle?: string
  title?: string
}

export const IntroDeals = memo(function IntroDeals({
  description,
  pretitle,
  title,
}: Props) {
  return (
    <Container>
      {pretitle ? (
        <FadeInUp>
          <Line />
          <Pretitle>{pretitle}</Pretitle>
        </FadeInUp>
      ) : null}
      {title ? <Title text={title} /> : null}
      {description ? (
        <FadeInUp>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </FadeInUp>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  padding: 10vw 10vw 0;
  text-align: center;

  @media (max-width: 991px) {
    padding: 70px 30px;
    margin-top: 70px;
  }
`

const Pretitle = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.83vw;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 0.69vw;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  @media (max-width: 1439px) {
    font-size: 10px;
    margin-left: 12px;
  }
`

const Title = styled(AnimatedTitle)`
  margin-top: 2.08vw;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: bold;
  font-size: 4.16vw;
  line-height: 1.133;

  @media (max-width: 991px) {
    font-size: 28px;
    margin-top: 15px;
  }
`

const Description = styled.div`
  width: 24vw;
  max-width: 100%;
  margin: 2.08vw auto 0;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 300;
  font-size: 1.04vw;
  line-height: 1.73;

  @media (max-width: 1439px) {
    font-size: 15px;
    line-height: 26px;
  }

  @media (max-width: 991px) {
    width: 80%;
    margin-top: 15px;
  }
`
