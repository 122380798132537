import styled from '@emotion/styled'
import React, { memo } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  items: ItemProps[]
}

export const DealsList = memo(function DealsList({ items }: Props) {
  if (!items) {
    return null
  }
  if (items.length < 1) {
    return null
  }

  return (
    <Container>
      <Items>
        {items
          .sort((a: any, b: any) => a.sortOrder - b.sortOrder)
          .map((item, index) => (
            <Item key={index} {...item} index={index} />
          ))}
      </Items>
    </Container>
  )
})

const Container = styled.section`
  padding: 8vw 10vw 10vw;

  @media (max-width: 991px) {
    padding: 30px;
    padding-top: 0;
  }
`

const Items = styled.div`
  margin-bottom: -5.9vw;

  @media (max-width: 991px) {
    margin-bottom: 0;
  }
`
