import styled from '@emotion/styled'
import { Accordion } from 'app/components/Common/Accordion'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import React, { memo } from 'react'

interface ItemProps {
  description?: string
  title: string
}

export interface Props {
  cta?: ButtonProps
  ctaSecondary?: ButtonProps
  description?: string
  items?: ItemProps[]
  label?: string
  subtitle?: string
  slug?: string
  title?: string
  index?: number
}

export const Item = memo(function Item({
  items,
  cta,
  ctaSecondary,
  description,
  label,
  subtitle,
  slug,
  title,
  index = 0,
}: Props) {
  return (
    <FadeInUp>
      <Container id={slug ? slug : undefined} className={`item--${index % 3}`}>
        <Box>
          {title ? <Title>{title}</Title> : null}
          {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
        </Box>

        <Info>
          {label ? (
            <FadeInUp>
              <Label>{label}</Label>
            </FadeInUp>
          ) : null}
          {description ? (
            <FadeInUp>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </FadeInUp>
          ) : null}
          {items ? (
            <FadeInUp>
              <Accordions>
                <Accordion items={items} />
              </Accordions>
            </FadeInUp>
          ) : null}
          {cta || ctaSecondary ? (
            <Buttons>
              {cta ? (
                <FadeInUp>
                  <StyledButton {...cta} />
                </FadeInUp>
              ) : null}
              {ctaSecondary ? (
                <FadeInUp delay={0.15}>
                  <StyledButton {...ctaSecondary} />
                </FadeInUp>
              ) : null}
            </Buttons>
          ) : null}
        </Info>
      </Container>
    </FadeInUp>
  )
})

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 23.88vw;
  height: 26.66vw;
  margin-right: 4.16vw;
  padding: 3.33vw;
  border-radius: 0.69vw;

  @media (max-width: 991px) {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 20px;
    border-radius: 6px;
  }
`

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 5.9vw;
  scroll-margin-top: 5vw;

  &.item--0 ${Box} {
    background-color: ${({ theme }) => theme.colors.variants.primaryLight};
  }
  &.item--1 ${Box} {
    background-color: ${({ theme }) => theme.colors.variants.neutralDark2};
  }
  &.item--2 ${Box} {
    background-color: ${({ theme }) => theme.colors.variants.primaryDark};
  }

  @media (max-width: 991px) {
    display: block;
    margin-bottom: 70px;
  }
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: bold;
  font-size: 2.5vw;
  line-height: 1.11;

  @media (max-width: 991px) {
    font-size: 22px;
  }
`

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 1.38vw;

  @media (max-width: 991px) {
    font-size: 15px;
    margin-top: 20px;
  }
`

const Info = styled.div`
  width: 100%;
  max-width: 51.5vw;

  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 20px;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 1.38vw;

  @media (max-width: 991px) {
    font-size: 15px;
  }
`

const Description = styled.div`
  margin-top: 0.97vw;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 300;
  font-size: 1.04vw;
  line-height: 1.73;

  @media (max-width: 1439px) {
    font-size: 15px;
    line-height: 26px;
  }

  @media (max-width: 991px) {
    margin-top: 15px;
  }
`

const Accordions = styled.div`
  margin-top: 3.33vw;

  @media (max-width: 991px) {
    margin-top: 20px;
  }
`

const Buttons = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: 991px) {
    margin-left: -12px;
    display: block;
    margin-top: 20px;
  }
`

const StyledButton = styled(Button)`
  margin-top: 2.77vw;

  &:last-of-type {
    margin-left: 0.83vw;

    @media (max-width: 1439px) {
      margin-left: 12px;
    }
  }

  @media (max-width: 991px) {
    margin-top: 20px;
    margin: 10px auto !important;
  }
`
